import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "refresh-cube" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "neutral-1 pt-2 pb-4 px-5" }, [_c("h2", { staticClass: "import-title mb-3 neutral-10--text" }, [_vm._v("Refresh Cube (PPV)")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": _vm.onRefreshClick } }, [_vm._v("Refresh Cube")])], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "RefreshCube",
  data() {
    return {
      apiUrl,
      isLoading: false,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "refresh-cube",
          title: "Refresh Cube",
          path: "/ppv-actuals/refresh-cube"
        }
      ]
    };
  },
  methods: {
    onRefreshClick() {
      this.isLoading = true, this.axios.post(`${this.apiUrl}/ppv/ppv/refresh-cubes`).then((res) => {
        this.isLoading = false;
        this.$notification.warning({
          message: res.data.message
        });
      });
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
